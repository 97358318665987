.Gallery-section{
  height: 100%;
  width: 100%;
  height: 100vh;
  width: 100vw;
  margin-top: 50px;
}

.row1{
  display: flex;
  justify-content: center;
  align-items: center;
}

.row2{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  margin: 10px
}

.Gallery-legend{
  text-align: center;
}

.ui.dimmer{
  background-color: rgb(23, 29, 75,.85);
}

